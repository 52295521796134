import request from '/src/utils/request'

//添加航次估算全部信息
export function addvoyage(param) {
  return request({
    url: `/api/vovageCalculator/voyage/saveAll`,
    method: 'post',
    data: param
  })
}

//修改航次估算信息
export function updatevoyage(param) {
  return request({
    url: `/api/vovageCalculator/voyage/save`,
    method: 'post',
    data: param
  })
}

//检索航次估算结果
export function selectvoyage(param) {
  return request({
    url: `/api/vovageCalculator/voyage/getAllList`,
    method: 'post',
    data: param
  })
}

//删除航次估算结果
export function deletevoyage(param) {
  return request({
    url: `/api/vovageCalculator/voyage/delete`,
    method: 'post',
    data: param
  })
}

//收藏航次估算结果
export function collectvoyage(param) {
  return request({
    url: `/api/vovageCalculator/selectVoyage/save`,
    method: 'post',
    data: param
  })
}
export function selectcollectvoyage(param) {
  return request({
    url: `/api/vovageCalculator/selectVoyage/getmylist`,
    method: 'post',
    data: param
  })
}

//取消收藏航次估算结果
export function cancelCollectvoyage(param) {
  return request({
    url: `/api/vovageCalculator/selectVoyage/delete`,
    method: 'post',
    data: param
  })
}
//根据id查询航次估算信息
export function selectVoyagebyId(param) {
  return request({
    url: `/api/vovageCalculator/voyage/getAllinfo/${param}`,
    method: 'post',
  })
}