<template>
  <el-form ref="dataObj" :model="dataObj"  label-width="90px" :rules="rules">
    <div class="portcard"  >
      <el-row>
        <el-col :span="16">
        </el-col>
        <el-col :span="4">
          <el-button  class="no-print" size="mini" plain type="success"  @click="add(index)" style="float: right;margin-top:3px;width: 90px!important">{{$t('message.estimate.ship.addindown')}}</el-button>
        </el-col>
        <el-col :span="1">
          <el-button  class="no-print" size="mini" plain type="primary"  @click="moveup(index)" style="float: right;margin-top:3px;width: 20px!important ;">↑</el-button>
        </el-col>
        <el-col :span="1">
          <el-button class="no-print" size="mini" plain type="primary"  @click="movedown(index)" style="float: right;margin-top:3px;width: 20px!important;">↓</el-button>
        </el-col>
        <el-col :span="2">
          <el-button class="no-print" size="mini" plain type="danger"  @click="del(index)" style="float: right;margin-top:3px;width: 55px!important">×</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item :label="$t('message.estimate.ship.shipstatus')">
            <el-select v-model="dataObj.shipStatus">
              <el-option
                  v-for="item in shipStatus"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="1"></el-col>
        <el-col :span="7">
          <el-form-item :label="$t('message.estimate.ship.port')" prop="portName">
            <el-autocomplete v-model="dataObj.portName"
                             style="width: 100%;"
                             :fetch-suggestions="querySearchPortName"
                             :trigger-on-focus="false"
                             @select="selectportname">
            </el-autocomplete>
          </el-form-item>
        </el-col>
        <el-col :span="1">
        </el-col>
        <el-col :span="7">
          <el-form-item :label="$t('message.estimate.ship.portfees')">
            <el-input type="number" v-model.number="dataObj.portFees" style="width: 100%;">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <span class="danwei">{{$t('message.moneydanwei')}}</span>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="7">
          <el-form-item :label="$t('message.estimate.ship.distance')">
            <el-input v-model="dataObj.voyageDistance" style="width: 100%;" placeholder="0.00">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <span class="danwei">NM</span>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="$t('message.estimate.ship.seadays')">
            <el-input type="number" v-model.number="dataObj.seaDays" style="width: 100%;">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <span class="danwei">{{$t('message.estimate.danwei.days')}}</span>
        </el-col>
        <el-col :span="7">
          <el-form-item :label="$t('message.estimate.ship.idledays')">
            <el-input type="number" v-model.number="dataObj.idleDays" style="width: 100%;">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <span class="danwei">{{$t('message.estimate.danwei.days')}}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <el-form-item :label="$t('message.estimate.ship.workdays')">
            <el-input type="number" v-model.number="dataObj.workDays" style="width: 100%;">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
          <span class="danwei">{{$t('message.estimate.danwei.days')}}</span>
        </el-col>
        <el-col :span="7">
          <el-form-item label="ETA" label-width="20px" prop="eta">
            <el-date-picker
                style="width:100%"
                v-model="dataObj.eta"
                type="datetime"
                value-format="yyyy-MM-ddHH:mm:ss"
                :placeholder="$t('message.estimate.ship.accuratetominutes')">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="1">
        </el-col>
        <el-col :span="7">
          <el-form-item label="ETD" label-width="20px" prop="etd">
            <el-date-picker
                style="width:100%"
                v-model="dataObj.etd"
                type="datetime"
                value-format="yyyy-MM-ddHH:mm:ss"
                :placeholder="$t('message.estimate.ship.accuratetominutes')"
                @input="etdtime">
            </el-date-picker>
            <!--<el-input v-model="dataObj.etd" style="width: 100%;" :placeholder="$t('message.estimate.ship.accuratetominutes')">-->
            <!--</el-input>-->
          </el-form-item>
        </el-col>
      </el-row>
<!--      <el-divider><i class="el-icon-mobile-phone"></i></el-divider>-->
    </div>
  </el-form>


</template>

<script>
    import {mapGetters} from 'vuex'
    import emitter from "../../utils/mitt"
  export default {
    name: "Portcard",
    props:{
      dataObj:Object, //传进来的信息对象
      index:Number,
      shipForm:Object
    },
    data(){
      const validateeta = (rule, value, callback) => {
      //  console.log(this.dataObj.etd,this.dataObj.eta)
        if(this.dataObj.etd!==''){
          if ( this.dataObj.eta!=='undefined' && this.dataObj.etd!=='undefined'&& this.dataObj.eta > this.dataObj.etd) {
            callback(new Error(window.vm.$t('message.startdatevalide')))
          } else {
            callback()
          }
        }
      };
      const validateetd = (rule, value, callback) => {
       // console.log(this.dataObj.etd,this.dataObj.eta)
          if (this.dataObj.eta!=='undefined'&& this.dataObj.eta > this.dataObj.etd && this.dataObj.etd!=='undefined') {
            callback(new Error(window.vm.$t('message.enddatevalide')))
          } else {
            callback()
          }
      };

      return{
        portName:'',
        totalPortFees:'',
        totalVoyageDistance:'',
        totalSeaDays:'',
        idleDays:'',
        workDays:'',
        eta:'',
        etd:'',
        shipStatus:[{
          value: '1',
          label: '装货'
        }, {
        value: '2',
          label: '卸货'
        },{
          value: '3',
          label: '压载'
        }
        ],
        rules:{
          portName: [{ required: true, message: 'Please input the port name', trigger: ['blur','change'] }],
          etd:[{validator:validateetd,trigger:['blur','change']}],
          eta:[{validator:validateeta,trigger:['blur','change']}],
        }

      }
    },
    computed:{
      ...mapGetters(['isMobile','lang','portnamegroup']),
    },
    methods:{
      etdtime(){
        if(this.dataObj.etd !=null && this.dataObj.voyageDistance !=null ){
          if(this.shipForm[this.index+1] == null){
            this.add(this.index)
            let date = new Date(this.dataObj.etd);
            date = date.getFullYear() + '-'
                + (date.getMonth()+1) + '-'   // 这里加1是因为getMonth()的返回值是为0~11
                + date.getDate() + ' '
                + date.getHours() + ':'
                + date.getMinutes() + ':'
                + date.getSeconds();
            date = Date.parse(new Date(date))/1000; // 转换成时间戳，返回值是ms，除1000后转化成秒
            date += (86400) * this.dataObj.voyageDistance; // 一天是86400秒
            let newDate = new Date(parseInt(date) * 1000); // 把时间戳转换成日期
            let etatime = newDate.getFullYear() + '-'
                + (newDate.getMonth()+1) + '-'
                + newDate.getDate() + ' '
                + newDate.getHours() + ':'
                + newDate.getMinutes() + ':'
                + newDate.getSeconds();
            emitter.emit('etatime'+this.index+1,etatime)
          }
        }
      },
      add(n){
        this.$emit('addport',n);
      },
      moveup(n){
        this.$emit('cardmove',n,0);
      },
      movedown(n){
        this.$emit('cardmove',n,1);
      },
      //触发父组件的删除方法
      del(n){
        this.$emit('del',n);
      },
      querySearchPortName(queryString,callback){
        this.$store.commit('metadata/querySearchPort',{"queryString":queryString})
        callback(this.portnamegroup)
      },
      selectportname(item){
        if(this.lang == 'en'){
          this.dataObj.portId = item.id
          this.dataObj.portCname = item.cname
          this.dataObj.portEname = item.value
        }else {
          this.dataObj.portId = item.id
          this.dataObj.portCname = item.value
          this.dataObj.portEname = item.ename
        }
      },
      submit(){
       // console.log('port submit')
          if(this.dataObj.portId!==''){
            if(this.dataObj.eta =='' || this.dataObj.etd ==''){
              this.$emit('getValidate',this.index,true)
            }
            else if(this.dataObj.eta !=='' && this.dataObj.etd !==''&& this.dataObj.eta < this.dataObj.etd ){
              this.$emit('getValidate',this.index,true)
            }else{
              this.$emit('getValidate',this.index,false)
            }
          }else{
            this.$emit('getValidate',this.index,false)
            return false
          }
      }

    },
    mounted(){
      emitter.on('etatime'+this.index,data =>{
        if(data)
        this.dataObj.eta = data
      })
    }
  };
</script>

<style scoped lang="scss">
    /*.portcard:hover{*/
        /*border:3px solid #def5ff;*/
    /*}*/
    /*.portcard{*/
        /*border-radius: 5px;*/
        /*//background: #def5ff;*/
        /*height: 100%;*/
        /*width: 100%;*/
        /*//border-bottom: solid 1px rgba(0, 0, 0, 0.34);*/
    /*}*/
</style>