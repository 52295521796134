<template>
  <el-form ref="dataObj" :model="dataObj"  label-width="90px" :rules="rules">

    <div class="cargo">
        <el-row>
            <el-col :span="18">
            </el-col>
            <el-col :span="4">
                <el-button class="no-print" size="mini" plain type="success"  @click="add(index)" style="float: right;margin-top:3px;width: 90px!important">{{$t('message.estimate.ship.addindown')}}</el-button>
            </el-col>
            <el-col :span="2">
                <el-button class="no-print" size="mini" plain type="danger"  @click="del(index)" style="float: right;margin-top:3px;width: 55px!important">×</el-button>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.ship.cargoname')" prop="cargoName">
                    <el-autocomplete v-model="dataObj.cargoName"
                                     style="width: 100%;"
                                     :fetch-suggestions="querySearchCargoName"
                                     :trigger-on-focus="false"
                                     @select="selectcargoname">
                    </el-autocomplete>
                </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.ship.quantity')">
                    <el-input type="number" v-model.number="dataObj.quantity" style="width: 100%;">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="1">
              <span class="danwei">{{$t('message.T')}}</span>
<!--                <el-select style="margin-top: 5px;" v-model="dataObj.quantitydanwei">
                    <el-option
                            v-for="item in danwei"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>-->
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.ship.feight')">
                    <el-input v-model="dataObj.freight" style="width: 100%;">
                    </el-input>
                </el-form-item>
            </el-col>
          <el-col :span="1">
            <span class="danwei">{{$t('message.moneydanwei')}}/MT</span>
          </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.ship.feighttax')">
                    <el-input v-model="dataObj.freightTax" style="width: 100%;">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="1">
                <span class="danwei">%</span>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.ship.discountcomm')">
                    <el-input v-model="dataObj.discountComm" style="width: 100%;">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="1">
                <span class="danwei">%</span>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.ship.brokercomm')">
                    <el-input v-model="dataObj.brokerComm" style="width: 100%;">
                    </el-input>
                </el-form-item>
            </el-col>
            <el-col :span="1">
                <span class="danwei">%</span>
            </el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.ship.others')">
                    <el-input v-model="dataObj.others" style="width: 100%;">
                    </el-input>
                </el-form-item>
            </el-col>
          <el-col :span="1">
            <span class="danwei">{{$t('message.moneydanwei')}}</span>
          </el-col>
        </el-row>
        <el-row>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.cargo.loadingport')" prop="loadingPortName">
                    <el-autocomplete v-model="dataObj.loadingPortName"
                                     style="width: 100%;"
                                     :trigger-on-focus="false"
                                     :fetch-suggestions="querySearchLoadPort"
                                     @select="selectloadport">
                    </el-autocomplete>
                </el-form-item>
            </el-col>
            <el-col :span="1"></el-col>
            <el-col :span="7">
                <el-form-item :label="$t('message.estimate.cargo.dischargeport')" prop="dischargePortName">
                    <el-autocomplete v-model="dataObj.dischargePortName"
                                     style="width: 100%;"
                                     @select="selectdischargeport"
                                     :trigger-on-focus="false"
                                     :fetch-suggestions="querySearchDischargePort">
                    </el-autocomplete>
                </el-form-item>
            </el-col>
        </el-row>
    </div>
  </el-form>
</template>

<script>
    import {mapGetters} from  'vuex'
  export default {
    name: "Cargocard",
    props:{
      dataObj:Object, //传进来的信息对象
      index:Number
    },
    data(){
      return{
        // id:'',
        // recorderId:'',
        // recordTime:'',
        // voyageId:'',
        // cargoNameId:'',
        // cargoEname:'',
        // cargoCname:'',
        // quantity:'',
        // freight:'',
        // discountComm:'',
        // brokerComm:'',
        // freightTax:'',
        // dischargePortId:'',
        // dischargePortEname:'',
        // dischargePortCname:'',
        // loadingPortId:'',
        // loadingPortEname:'',
        // loadingPortCname:'',
        // sort:'',
        // updateTime:'',
        // others:'',
        danwei:[
          {
            value:'1',
            label:'MT'
          },
          {
            value:'2',
            label:'CBM'
          },
          {
            value:'3',
            label:'CBF'
          }],
        rules:{
          cargoName: [{ required: true, message: 'Please input the cargo name', trigger: ['blur','change'] }],
          // dischargePortName: [{ required: true, message: 'Please input the port name', trigger: ['blur','change'] }],
          // loadingPortName: [{ required: true, message: 'Please input the port name', trigger: ['blur','change'] }],

        }
      }
    },
    computed:{
      ...mapGetters(['isMobile','lang','portnamegroup','cargonamegroup']),
    },
    methods:{
      add(n){
        this.$emit('addcargo',n);
      },
      //触发父组件的删除方法
      del(n){
        this.$emit('delcargo',n);
      },
      querySearchCargoName(queryString, callback){
        this.$store.commit('metadata/querySearchCargo',queryString)
        callback(this.cargonamegroup)
      },
      //装货港口名联想输入
      querySearchLoadPort(queryString, callback){
        this.$store.commit('metadata/querySearchPort',{"queryString":queryString})
        callback(this.portnamegroup)
      },
      //卸货港口名联想输入
      querySearchDischargePort(queryString, callback){
        this.$store.commit('metadata/querySearchPort',{"queryString":queryString})
        callback(this.portnamegroup)
      },
      selectcargoname(item){
        if(this.lang == 'en'){
          this.dataObj.cargoNameId = item.id
          this.dataObj.cargoCname = item.cname
          this.dataObj.cargoEname = item.value
        }else {
          this.dataObj.cargoNameId = item.id
          this.dataObj.cargoCname = item.value
          this.dataObj.cargoEname = item.ename
        }
      },
      selectloadport(item){
        if(this.lang == 'en'){
          this.dataObj.loadingPortId = item.id
          this.dataObj.loadingPortCname = item.cname
          this.dataObj.loadingPortEname = item.value
        }else {
          this.dataObj.loadingPortId = item.id
          this.dataObj.loadingPortCname = item.value
          this.dataObj.loadingPortEname = item.ename
        }
      },
      selectdischargeport(item){
        if(this.lang == 'en'){
          this.dataObj.dischargePortId = item.id
          this.dataObj.dischargePortCname = item.cname
          this.dataObj.dischargePortEname = item.value
        }else {
          this.dataObj.dischargePortId = item.id
          this.dataObj.dischargePortCname = item.value
          this.dataObj.dischargePortEname = item.ename
        }
      },
      submit(){
        this.$refs.dataObj.validate(valid =>{
          if(valid){
            this.$emit('getValidate',this.index,true)
          }else{
            this.$emit('getValidate',this.index,false)
            return false
          }
        })
      }
    }
  };
</script>

<style scoped>

</style>