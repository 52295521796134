<template>
    <div class="voyage" id="print">
        <div class="elsteps">
            <el-form v-if="isEorD !== '2'" ref="voyageForm" :model="voyageForm"  label-width="90px" :rules="rules">
                <el-steps direction="vertical" finish-status="success" :active="Number(activeNames)">
                    <el-step :title="$t('message.estimate.voyage.name')">
                        <template #description>
                            <el-collapse :class="activeNames"  v-model="activeNames" @change="handleChange" accordion id="demo1">
                                <el-collapse-item class="voyage_box" :title="$t('message.estimate.voyage.name')" name="1">
                                    <div class="voyage_info" >
                                        <el-card class="box-card">
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.voyage.vesselname')" prop="vesselName">
                                                        <el-autocomplete
                                                                v-model="voyageForm.vesselName"
                                                                style="width: 100%;"
                                                                :fetch-suggestions="querySearchVessel"
                                                                :trigger-on-focus="false"
                                                                @change="changevessel"
                                                                @select="selectvessel">
                                                        </el-autocomplete>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.voyage.voyage')" prop="voyage">
                                                        <el-input v-model="voyageForm.voyage" style="width: 100%;">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.voyage.dwt')">
                                                        <el-input v-model="voyageForm.dwt" style="width: 100%;" >
                                                          <template #append>
                                                            {{$t('message.T')}}
                                                          </template>
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                    <span class="danwei"></span>
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.addtonnage.build')">
                                                      <el-date-picker
                                                          v-model="voyageForm.built"
                                                          type="year"
                                                          :placeholder="$t('message.addtonnage.pickyear')"
                                                      />

                                                    </el-form-item>
                                                </el-col>
<!--                                                <el-col :span="1">-->

<!--                                                </el-col>-->
                                            </el-row>
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.voyage.knot')">
                                                        <el-input v-model="voyageForm.knot" style="width: 100%;" placeholder="默认13节">
                                                          <template #append>
                                                            Knot
                                                          </template>
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                              <el-col :span="1">
                                                <span class="danwei"></span>
                                              </el-col>
                                              <el-col :span="10">
                                                <el-form-item :label="$t('message.estimate.voyage.cbm')">
                                                  <el-input v-model="voyageForm.cbm" style="width: 100%;"> <template #append>
                                                    Cbm
                                                  </template>
                                                  </el-input>
                                                </el-form-item>
                                              </el-col>
<!--                                                <el-col :span="1">

                                                </el-col>-->
                                            </el-row>
                                        </el-card>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </template>
                    </el-step>
                    <el-step :title="$t('message.estimate.ship.name')">
                        <template #description>
                            <!--<el-button style="float: right;margin-top: 7px;margin-right: 10px" type="success" size="mini" @click="addport()" >添加港口</el-button>-->
                            <el-collapse :class="activeNames" v-model="activeNames" @change="handleChange" accordion id="demo2">
                                <el-collapse-item class="ship_box" :title="$t('message.estimate.ship.name')" name="2">
                                    <div class="ship_info">
                                        <el-card class="box-card" >
                                            <draggable  :list="shipForm" @change="log">
                                                <!--<transition-group>-->
                                                    <div v-for="(item,index) in shipForm" :key="index">
                                                        <Portcard  ref="portitem"  :index="index" :dataObj="item" @cardmove="cardmove" @addport="addport"  @del="del" @getValidate="getportValidate"></Portcard>
                                                    </div>
                                                <!--</transition-group>-->
                                            </draggable >
                                        </el-card>
                                        <!--动态增加船舶状态组件-->

                                        <!--<el-tabs   class="tabs-sort" v-model="editableTabsValue" type="card" :before-leave="beforeLeave" closable @tab-remove="removeTab">-->
                                        <!--<el-tab-pane-->
                                        <!--v-for="(item, index) in editableTabs"-->
                                        <!--:key="item.name"-->
                                        <!--::label="item.:title"-->
                                        <!--:name="item.name"-->
                                        <!--&gt;-->
                                        <!--{{item.content}}-->
                                        <!--</el-tab-pane>-->
                                        <!--<el-tab-pane :label='+' key="add" name="add" >-->
                                        <!--</el-tab-pane>-->
                                        <!--</el-tabs>-->
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </template>
                    </el-step>
                    <el-step :title="$t('message.estimate.cargo.name')">
                        <template #description>
                            <!--<el-button style="float: right;margin-top: 7px;margin-right: 10px" type="success" size="mini" @click="AddCargoRow()" >添加货物</el-button>-->
                            <el-collapse :class="activeNames" v-model="activeNames" @change="handleChange" accordion id="demo3">
                                <el-collapse-item class="cargo_box" :title="$t('message.estimate.cargo.name')" name="3">
                                    <div class="cargo_info">
                                        <el-card class="box-card">
                                            <draggable  :list="cargoForm" @change="log" >
                                                <!--<transition-group>-->
                                                    <div v-for="(item,index) in cargoForm" :key="index" >
                                                        <Cargocard  ref="cargoitem"  :index="index" :dataObj="item"  @addcargo="addcargo"  @delcargo="delcargo" @getValidate="getcargoValidate"></Cargocard>
                                                    </div>
                                                <!--</transition-group>-->
                                            </draggable >
                                        </el-card>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </template>
                    </el-step>
                    <el-step :title="$t('message.estimate.fuel.name')">
                        <template #description>
                            <el-collapse  :class="activeNames" v-model="activeNames" @change="handleChange" accordion id="demo4">
                                <el-collapse-item class="fuel_box" :title="$t('message.estimate.fuel.name')" name="4">
                                    <div class="fuel_info" >
                                        <el-card class="box-card">
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.fuel.fueltype')"  label-width="120px">
                                                        <el-select style="margin-top: 5px;" v-model="voyageForm.portFuelType  ">
                                                            <el-option
                                                                    selected=""
                                                                    v-for="item in fuelForm"
                                                                    :key="item.value"
                                                                    :label="item.label"
                                                                    :value="item.value">
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-form-item>
                                                        <el-select style="margin-top: 5px;" v-model="voyageForm.doFuelType">
                                                            <el-option
                                                                    v-for="item in fuelForm"
                                                                    :key="item.value"
                                                                    :label="item.label"
                                                                    :value="item.value">
                                                            </el-option>
                                                        </el-select>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <!--这里的sailballast、heavysail、waitport、work均未在后端找到-->
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.fuel.sailballast')" label-width="120px">
                                                        <el-input v-model="voyageForm.portFuelLightSeaPerday" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-form-item>
                                                        <el-input v-model="voyageForm.doFuelLightSeaPerday" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.fuel.heavysail')"  label-width="120px">
                                                        <el-input v-model="voyageForm.portFuelHeavySeaPerday" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-form-item>
                                                        <el-input v-model="voyageForm.doFuelHeavySeaPerday" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.fuel.waitport')" label-width="120px">
                                                        <el-input v-model="voyageForm.portFuelPortPerday" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-form-item>
                                                        <el-input v-model="voyageForm.doFuelPortPerday" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.fuel.work')" label-width="120px">
                                                        <el-input v-model="voyageForm.portWork" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-form-item>
                                                        <el-input v-model="voyageForm.doWork" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="10">
                                                    <el-form-item :label="$t('message.estimate.fuel.fuelprice')" label-width="120px">
                                                        <el-input v-model="voyageForm.foPrice" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                    <el-button  style="border: white;font-size: 15px;padding: 5px 10px" class="el-icon-search"></el-button>
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-form-item >
                                                        <el-input v-model="voyageForm.doPrice" style="width: 100%;" placeholder="0.00" type="number" step="0.01">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :span="1">
                                                    <el-button  style="border: white;font-size: 15px;padding: 5px 10px" class="el-icon-search"></el-button>
                                                </el-col>
                                            </el-row>
                                        </el-card>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </template>
                    </el-step>
                    <el-step :title="$t('message.estimate.rent.name')">
                        <template #description>
                            <el-collapse :class="activeNames" v-model="activeNames" @change="handleChange" accordion id="demo5">
                                <el-collapse-item class="shipfee_box" :title="$t('message.estimate.rent.name')" name="5">
                                    <div class="ship_fee">
                                        <el-card class="box-card">
                                            <el-row>
                                                <el-col :span="17">
                                                    <el-form-item :label="$t('message.estimate.rent.rentperday')" label-width="100px">
                                                        <el-input v-model="voyageForm.shipRentPerday" style="width: 100%;" step="0.01"  type="number" placeholder="0.00">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                              <el-col :span="1">
                                                <span class="danwei">{{$t('message.moneydanwei')}}</span>
                                              </el-col>
                                            </el-row>
<!--                                          <el-row>
                                          <el-col :span="17">
                                            <el-form-item :label="$t('message.estimate.rent.Equivalentrentlevel')" label-width="100px">
                                              <el-input v-model="voyageForm.dailyEqualRent" style="width: 100%;" step="0.01"  type="number" placeholder="0.00">
                                              </el-input>
                                            </el-form-item>
                                          </el-col>
                                            <el-col :span="1">
                                              <span class="danwei">{{$t('message.moneydanwei')}}</span>
                                            </el-col>
                                          </el-row>-->
                                          <el-row>
                                            <el-col :span="19">
                                              <el-form-item :label="$t('message.estimate.allcost.tontax_')" label-width="100px">
                                                <el-input style="width:90px" v-model.number="voyageForm.totalTonnageDues_T" placeholder="总吨" :min="0" oninput="if(value<0)value=0" type="number">
                                                </el-input>MT X<el-input  style="width:90px"  v-model.number="voyageForm.totalTonnageDues_tax" placeholder="税率" :min="0" oninput="if(value<0)value=0" type="number">
                                              </el-input>% X<el-input   style="width:90px" v-model.number="voyageForm.totalTonnageDues_day" placeholder="天数" :min="0" oninput="if(value<0)value=0" type="number">
                                              </el-input>days
                                              </el-form-item>
                                            </el-col>
                                          </el-row>
<!--                                            <el-row>
                                                <el-col :span="17">
                                                    <el-form-item :label="$t('message.estimate.rent.commission')" label-width="100px">
                                                        <el-input v-model="voyageForm.commission" style="width: 100%;" step="0.01"  type="number" placeholder="0.00">
                                                          <template #append>%
                                                          </template>
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>-->
                                        </el-card>
                                    </div>
				</el-collapse-item>
                            </el-collapse>
			</template> </el-step>
                    <el-step :title="$t('message.estimate.otherfee.name')">
                        <template #description>
                            <el-collapse :class="activeNames"  v-model="activeNames" @change="handleChange" accordion id="demo6">
                                <el-collapse-item class="other_box" :title="$t('message.estimate.otherfee.name')" name="6">
                                    <div class="other_info">
                                        <el-card class="box-card">
                                            <el-row>
                                                <el-col :span="11">
                                                    <el-form-item :label="$t('message.estimate.otherfee.Demurrage')" label-width="80px">
                                                        <el-input v-model="voyageForm.demurrageCharges" style="width: 100%;" step="0.01"  type="number" placeholder="0.00">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                              <el-col :span="1">
                                                <span class="danwei">{{$t('message.moneydanwei')}}</span>
                                              </el-col>
                                              <el-col :span="11">
                                                <el-form-item :label="$t('message.estimate.otherfee.subsidy')" label-width="80px">
                                                  <el-input v-model="voyageForm.otherMoney" style="width: 100%;" step="0.01"  type="number" placeholder="0.00">
                                                  </el-input>
                                                </el-form-item>
                                              </el-col>
                                              <el-col :span="1">
                                                <span class="danwei">{{$t('message.moneydanwei')}}</span>
                                              </el-col>
                                            </el-row>
                                            <el-row>
                                                <el-col :span="11">
                                                    <el-form-item :label="$t('message.estimate.otherfee.dispatchfee')" label-width="80px">
                                                        <el-input v-model="voyageForm.dispatchMoney" style="width: 100%;" step="0.01"  type="number" placeholder="0.00">
                                                        </el-input>
                                                    </el-form-item>
                                                </el-col>
                                              <el-col :span="1">
                                                <span class="danwei">{{$t('message.moneydanwei')}}</span>
                                              </el-col>
                                              <el-col :span="11">
                                                <el-form-item :label="$t('message.estimate.otherfee.warehousesweepfee')" label-width="80px">
                                                  <el-input v-model="voyageForm.scanStorage" style="width: 100%;" step="0.01"  type="number" placeholder="0.00">
                                                  </el-input>
                                                </el-form-item>
                                              </el-col>
                                              <el-col :span="1">
                                                <span class="danwei">{{$t('message.moneydanwei')}}</span>
                                              </el-col>
                                            </el-row>
                                        </el-card>
                                    </div>
                                </el-collapse-item>
                            </el-collapse>
                        </template>
                    </el-step>
                </el-steps>
            </el-form>

        </div>
      <el-dialog
          :title="$t('message.email.modifyinformation')"
          v-model="deit_dialog"
          width="30%"
          :before-close="handleClose"
      >
        <span>{{$t('message.email.sentence1')}}</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="deit_dialog = false">{{$t('message.email.cancel')}}</el-button>
            <el-button type="primary" @click="submits">{{$t('message.email.ok')}}</el-button>
          </span>
        </template>
      </el-dialog>
        <div class="out">
            <el-form>
                <el-row>
                    <el-col :span="20">
                        <div class="voyage_outcome" id="right1">
                            <el-card class="box-card">
                                <template #header>
                                    <el-divider>{{$t('message.estimate.result.name')}}</el-divider>
                                </template>
                                <el-row class="el-row-inner">
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.result.totaldistance')" label-width="120px" prop="totaldistance">
                                          <span style="position: absolute;right: 3px">{{totalVoyageDistance}}NM</span>
<!--                                          <el-input :disabled="true" v-model="totalVoyageDistance" style="width: 100%;">-->
<!--                                            </el-input>-->
<!--                                            &lt;!&ndash;<span>{{totalVoyageDistance}}</span>&ndash;&gt;-->
                                        </el-form-item>
                                    </el-col>
<!--                                    <el-col :span="1">
                                        <span class="danwei"></span>
                                    </el-col>-->
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.result.allvoyagetime')" label-width="120px">
                                            <!--这里的allvoyagetime未在后端找到-->
<!--                                            <el-input v-model="allvoyagetime" :disabled="true" style="width: 100%;" >-->
<!--                                            </el-input>-->
                                          <span style="position: absolute;right: 3px">{{allvoyagetime}}{{$t('message.estimate.danwei.days')}}</span>
                                        </el-form-item>
                                    </el-col>
<!--                                    <el-col :span="1">
                                        <span class="danwei"></span>
                                    </el-col>-->
                                </el-row>
                                <el-row class="el-row-inner">
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.result.totaldays')" label-width="120px">
<!--                                            <el-autocomplete v-model="totalSailingDays" :disabled="true" style="width: 100%;">-->
<!--                                            </el-autocomplete>-->
                                          <span style="position: absolute;right: 3px">{{totalSailingDays}}{{$t('message.estimate.danwei.days')}}</span>
                                        </el-form-item>
                                    </el-col>
<!--                                    <el-col :span="1">
                                        <span class="danwei"></span>
                                    </el-col>-->
                                    <el-col :span="10">
                                      <el-form-item :label="$t('message.estimate.result.totalseadays')" label-width="120px">
                                        <!--                                            <el-input v-model="totalSeaDays" :disabled="true" style="width: 100%;" >-->
                                        <!--                                            </el-input>-->
                                        <span style="position: absolute;right: 3px">{{totalSeaDays}}{{$t('message.estimate.danwei.days')}}</span>
                                      </el-form-item>
                                    </el-col>
<!--                                  <el-col :span="1">
                                    <span class="danwei"></span>
                                  </el-col>-->
                                </el-row>
                              <el-row class="el-row-inner">
                                <el-col :span="15">
                                  <el-form-item :label="$t('message.estimate.result.lastetd')" label-width="120px">
                                    <span style="position: absolute;right: 3px">{{firstPortEta}}</span>

                                  </el-form-item>
                                </el-col>
                              </el-row>
                                <el-row class="el-row-inner">
                                    <el-col :span="15">
                                      <el-form-item :label="$t('message.estimate.result.firsteta')" label-width="120px">
                                        <span style="position: absolute;right: 3px">{{lastPortEtd}}</span>
                                      </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <div class="total_cost" id="right2">
                            <el-card class="box-card">
                                <template #header>
                                    <el-divider>{{$t('message.estimate.allcost.name')}}</el-divider>
                                </template>
                                <el-row class="el-row-inner">
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.totalfuel')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{totalFuelFees}}{{$t('message.moneydanwei')}}</span>
<!--                                            <el-autocomplete v-model="totalFuelFees" :disabled="true" style="width: 100%;">-->
<!--                                            </el-autocomplete>-->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.totalportfee')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{totalPortFees}}{{$t('message.moneydanwei')}}</span>
<!--                                            <el-autocomplete v-model="totalPortFees" :disabled="true" style="width: 100%;">-->
<!--                                            </el-autocomplete>-->
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row class="el-row-inner">
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.addresscomm')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{discountComm}}%</span>
<!--                                          <el-input v-model="discountComm"  :disabled="true" style="width: 100%;" >-->
<!--                                            </el-input>-->
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.brokercomm')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{brokerComm}}%</span>
<!--                                          <el-input v-model="brokerComm" :disabled="true" style="width: 100%;" >-->
<!--                                            </el-input>-->
                                        </el-form-item>
                                    </el-col>
                                </el-row>

                                <el-row class="el-row-inner">
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.rent')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{totalShipRent}}{{$t('message.moneydanwei')}}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.rencomm')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{totalRentCost}}%</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row class="el-row-inner">
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.tontax')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{totalTonnageDues}}{{$t('message.moneydanwei')}}</span>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.othercost')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{totalOtherCost}}{{$t('message.moneydanwei')}}</span>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row class="el-row-inner">
                                    <el-col :span="10">
                                      <el-form-item :label="$t('message.estimate.rent.Equivalentrentlevel')" label-width="100px">
                                        <span style="position: absolute;right: 3px;">{{dailyEqualRent}}{{$t('message.moneydanwei')}}</span>
<!--                                        <el-input v-model="voyageForm.dailyEqualRent" style="width: 100%;" step="0.01"  type="number" placeholder="0.00">
                                        </el-input>-->
                                      </el-form-item>
                                    </el-col>
                                    <el-col :span="10">
                                        <el-form-item :label="$t('message.estimate.allcost.totalcost')" label-width="100px">
                                          <span style="position: absolute;right: 3px;">{{totalCost}}{{$t('message.moneydanwei')}}</span>

<!--                                          <el-input v-model="totalCost" :disabled="true" style="width: 100%;" >-->
<!--                                            </el-input>-->
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-card>

                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        <div class="ship_fee" id="right3">
                            <el-card class="box-card">
                                <template #header>
                                    <el-divider>{{$t('message.estimate.budget.name')}}</el-divider>
                                </template>
                                <el-row class="el-row-inner">
                                    <el-col :span="20">
                                        <el-form-item :label="$t('message.estimate.budget.freightrevenue')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{freightrevenue}}{{$t('message.moneydanwei')}}</span>
<!--                                            <el-autocomplete id="budget1" v-model="freightrevenue" :disabled="true" style="width: 100%;">-->
<!--                                            </el-autocomplete>-->
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                              <el-row class="el-row-inner">
                                    <el-col :span="20">
                                        <el-form-item v-if="loss>=0" :label="$t('message.estimate.budget.profit')" label-width="100px">
                                          <span class="input_loss2" style="position: absolute;right: 3px;color:green;">{{loss}}{{$t('message.moneydanwei')}}</span>

<!--                                          <el-input  class="input_loss2"   v-model="loss" :disabled="true" style="width: 100%">-->
<!--                                            </el-input>-->
                                        </el-form-item>
                                        <el-form-item v-else  :label="$t('message.estimate.budget.loss')" label-width="100px">
                                          <span class="input_loss1"  style="position: absolute;right: 3px;color:red;">{{loss}}{{$t('message.moneydanwei')}}</span>

<!--                                          <el-input    v-model="loss" :disabled="true" style="width: 100%">-->
<!--                                            </el-input>-->
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row  class="el-row-inner">
                                    <el-col :span="20">
                                        <el-form-item :label="$t('message.estimate.budget.profitper')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{profitper}}{{$t('message.moneydanwei')}}</span>

<!--                                          <el-autocomplete id="budget3" v-model="profitper" :disabled="true" style="width: 100%;">-->
<!--                                            </el-autocomplete>-->
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                                <el-row class="el-row-inner">
                                    <el-col :span="20">
                                        <el-form-item :label="$t('message.estimate.budget.profitperday')" label-width="100px">
                                          <span style="position: absolute;right: 3px">{{perfitperday}}{{$t('message.moneydanwei')}}</span>

<!--                                          <el-autocomplete id="budget4" v-model="perfitperday" :disabled="true" style="width: 100%;">-->
<!--                                            </el-autocomplete>-->
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="4"  >
                        <!--//这里面的内容是我们要导出的部分 id为"pdfDom"，和上面"htmlToPdf.js"文件中的id必须一致.此部分将就是pdf显示的部分-->
                        <el-button  style="float: left;width: 147%;text-align: center" type="primary" class="no-print" v-on:click="downPdf()">{{$t('message.generatereport')}}</el-button>
                    </el-col>
                    <el-col :span="11"></el-col>
                    <el-col :span="4">
                        <el-button v-if="isEorD === '1' " style="float: right;width: 147%;text-align: center" type="primary" class="no-print" @click="openedit"  :disabled="disabled">{{$t('message.modifyinformation')}}</el-button>
                        <el-button v-else style="float: right;width: 147%;text-align: center"  type="primary" class="no-print" @click="submits" :disabled="disabled">{{$t('message.saveinformation')}}</el-button>
                        <!--72001	航次估算中的船舶信息-添加 / 更新-->
                    </el-col>
                </el-row>
            </el-form>

        </div>
        </div>
<div id="test">
</div>
</template>

<script>
  import { defineComponent } from 'vue'
  import { VueDraggableNext } from 'vue-draggable-next'
  import Portcard from '../../components/portcard/Portcard'
  import Cargocard from '../../components/cargocard/Cargocard'
  import { mapGetters } from 'vuex'
  import {parseTime} from "../../utils";
  import  {addvoyage,selectVoyagebyId} from  '../../api/voyage'
 import  {getshipinfo} from  '../../api/metadata'
  import $ from 'jquery'

  export default {
    inject:['reload'],
    name: "VoyageEstimate",
    components: { Cargocard,Portcard, defineComponent, draggable: VueDraggableNext },
    data() {
      return {
        deit_dialog:false,
        lang:'',
        dollar:'USD',
        moneydanwei:'RMB',
        disabled:'',//按钮权限
        buttonpermission:[],
        isEorD:'',//当前是否是修改信息状态或者是详情页状态 规定 “1” 为修改 “2” 为详情
        voyageid:0,//路由传入的修改页或详情页的tonnage的id
        activeNames: '1',
        active: 0,
        enabled: true,
        dragging: false,
        lst: [{}],
        cargolst:[{}],
        num: 1,
        voyageForm: {  //航次信息
          totalTonnageDues_T:'',
          totalTonnageDues_tax:'',
          totalTonnageDues_day:'',
          vesselName:'',
          vesselCname: '',
          vesselEname: '',
          vesselId: '',
          voyage: '',
          dwt: '',
          cbm: '',
          knot: '',
          built:'',
          //燃油信息
          portFuelType:'LSFO',
          portFuelLightSeaPerday:'',
          portFuelHeavySeaPerday:'',
          portFuelPortPerday:'',
          portWork:'',
          foPrice:'',
          //柴油
          doFuelType:'MGO',
          doFuelLightSeaPerday:'',
          doFuelHeavySeaPerday:'',
          doFuelPortPerday:'',
          doWork:'',
          doPrice:'',
          //租金
          shipRentPerday:'',
          //租金佣金
          commission:'',
          //相当租金水平
        //  dailyEqualRent:'',
          //其他费用
          demurrageCharges:'',
          dispatchMoney:'',
          otherMoney:'',
          scanStorage:''
        },
        shipForm:[{
          sort:'',
          shipStatus:'',
          portId:'',
          portName:'',
          portEname:'',
          portCname:'',
          portFees:'',
          voyageDistance:'',
          seaDays:'',
          idleDays:'',
          workDays:'',
          eta:'',  //预计到港时间
          etd:''   //预计离港时间
        }
        ],
        cargoForm:[{
          sort:'',
          cargoNameId:'',
          cargoName:'',
          cargoCname:'',
          cargoEname:'',
          quantity:'',
          freight:'',
          freightTax:'',
          discountComm:'',
          brokerComm:'',
          others:'',
          loadingPortId:'',
          loadingPortEname:'',
          loadingPortCname:'',
          dischargePortId:'',
          dischargePortCname:'',
          dischargePortEname:''
        }],
        resultForm:{
          //航次计算结果
          totalVoyageDistance:'',
          totalSailingDays:'',
          totalSeaDays:'',
          allvoyagetime:'',
          firstPortEta:'',
          lastPortEtd:'',
          //燃油费
          totalFuelFees:'',
          //港口费用
          totalPortFees:'',
          totalDiscountComm:'',
          //相当租金水平
          dailyEqualRent:'',
          brokerComm:'',
          //总船舶租金
          totalShipRent:'',
          //租金佣金
          totalRentCost:'',
          totalTonnageDues:'',
          //其他费用
          totalOtherCost:'',
          totalCost:'',
          name:'',
          //收益
          totalFreightRevenue:'',
          profitLoss:'',
          profitPer:'',
          profitPerday:''

        },
        editableTabsValue: '2',
        fuelForm:[
          {
            value:'1',
            label:'MGO'
          },
          {
            value:'2',
            label:'LSFO'
          },
          {
            value:'3',
            label:'VLSFO'
          },
          {
            value:'4',
            label:'ULSFO'
          }
        ],
        tabIndex: 2,
        portvalidate:[],
        cargovalidate:[],
        rules: {
          vesselName: [{ message: window.vm.$t('message.shipvesselmessage'), required: true, trigger: ['blur','change']  }],
          voyage: [{ required: true, message:window.vm.$t('message.please_voyage'),trigger: ['blur','change']  }],
          // openStartDate: [{ type: 'date', validator: validateOpenStart, required: true, trigger: 'blur' }],
          // openEndDate: [{ type: 'date', validator: validateOpenEnd, required: true, trigger: 'blur' }],
          // senderEmail: [{ validator: validateSender, required: true, trigger: 'blur' }],
          // sendTime: [{ required: true, message: 'Please select the sendtime', trigger: 'blur' }]
        }
      }
    },
    watch:{
      '$route'(to,form){
        this.reload()
      },

    },

    computed:{
      ...mapGetters(['isMobile','lang','permission','vesselnamegroup']),
      //总航程距离
      totalVoyageDistance : function(){
        let total = 0 ;
        for (let i=0;i<this.shipForm.length;i++){
          if(typeof this.shipForm[i].voyageDistance == 'undefined' || this.shipForm[i].voyageDistance == '') total += 0
          else total += parseFloat(this.shipForm[i].voyageDistance)
        }
        return parseFloat(total).toFixed(2)
      },
      //吨税=总吨*吨税*天数
      totalTonnageDues:function() {
        let total = 0;
        if(this.voyageForm.totalTonnageDues_T == ''|| typeof this.voyageForm.totalTonnageDues_T =='undefined')  total*= 1
        else total = parseFloat(this.voyageForm.totalTonnageDues_T)
        if(this.voyageForm.totalTonnageDues_tax == ''|| typeof this.voyageForm.totalTonnageDues_tax =='undefined')  total*= 1
        else total *= parseFloat(this.voyageForm.totalTonnageDues_tax)/100
        if(this.voyageForm.totalTonnageDues_day == ''|| typeof this.voyageForm.totalTonnageDues_day =='undefined')  total*= 1
        else total *= parseFloat(this.voyageForm.totalTonnageDues_day)
        return parseFloat(total).toFixed(2);
      },
     //总航行时间=所有港口航行时间之和
      totalSailingDays:function() {
        let total = 0;
        for (let i=0;i<this.shipForm.length;i++){
          if(this.shipForm[i].seaDays == '' || typeof this.shipForm[i].seaDays == 'undefined') {
            total += 0
          }
          else {
            total += parseInt(this.shipForm[i].seaDays)

          }
        }
        return total
      },
      //航次总时间
      allvoyagetime:function() {
        let total = 0;
        for (let i=0;i<this.shipForm.length;i++){
           if(this.shipForm[i].seaDays == ''|| typeof this.shipForm[i].seaDays=='undefined')  total += 0
          else total += parseInt(this.shipForm[i].seaDays)
          if(this.shipForm[i].idleDays == ''|| typeof this.shipForm[i].idleDays=='undefined')  total += 0
            else total += parseInt(this.shipForm[i].idleDays)
          if(this.shipForm[i].workDays == ''|| typeof this.shipForm[i].workDays=='undefined') total += 0
          else total += parseInt(this.shipForm[i].workDays)
           // if(this.shipForm[i].idleDays == ''|| typeof this.shipForm[i].idleDays=='undefined' && this.shipForm[i].workDays == ''|| typeof this.shipForm[i].workDays=='undefined' && this.shipForm[i].seaDays == ''|| typeof this.shipForm[i].seaDays=='undefined')  total += 0
          // total += parseInt(this.shipForm[i].seaDays)+parseInt(this.shipForm[i].workDays)+parseInt(this.shipForm[i].idleDays)
        }
        return total;
      },
      //预计到港时间
      firstPortEta:function() {
         return parseTime(this.shipForm[this.shipForm.length - 1].eta,'{y}-{m}-{d} {h}:{i}:{s}');
      },
      //预计离港时间
      lastPortEtd:function() {
          return parseTime(this.shipForm[0].etd,'{y}-{m}-{d} {h}:{i}:{s}') ;
      },
      //总在港时间=所有港口待港天数+作业天数
      totalSeaDays:function() {
        let total = 0;
        for (let i=0;i<this.shipForm.length;i++){
          if(this.shipForm[i].workDays == ''|| typeof this.shipForm[i].workDays == 'undefined') total += 0
          else
          total += parseInt(this.shipForm[i].workDays)
          if(this.shipForm[i].idleDays == ''|| typeof this.shipForm[i].idleDays == 'undefined') total += 0
          else
          total +=parseInt(this.shipForm[i].idleDays)
        }
        return total;
      },
      //港口费用=各港口费用之和
      totalPortFees:function() {
        let total = 0;
        for (let i=0;i<this.shipForm.length;i++){
          if(this.shipForm[i].portFees == ''|| typeof this.shipForm[i].portFees=='undefined') total += 0
          else
          total += parseFloat(this.shipForm[i].portFees)
        }
        return parseFloat(total).toFixed(2);
      },
      //燃油费=柴油+燃油
      totalFuelFees:function(){
        let total = 0
        let foprice = 0,fohe = 0
        let doprice = 0,dohe = 0
        //燃油价格
        if(this.voyageForm.foPrice == ''|| typeof this.voyageForm.foPrice =='undefined') foprice = 0
        else foprice = parseFloat(this.voyageForm.foPrice)
        //燃油总量
        if(this.voyageForm.portFuelLightSeaPerday == ''|| typeof this.voyageForm.portFuelLightSeaPerday =='undefined') fohe =0
        else fohe = parseFloat(this.voyageForm.portFuelLightSeaPerday)
        if(this.voyageForm.portFuelHeavySeaPerday == ''|| typeof this.voyageForm.portFuelHeavySeaPerday =='undefined') fohe = fohe
        else fohe +=  parseFloat(this.voyageForm.portFuelHeavySeaPerday)
        if(this.voyageForm.portFuelPortPerday == ''|| typeof this.voyageForm.portFuelPortPerday =='undefined') fohe = fohe
        else fohe+=  parseFloat(this.voyageForm.portFuelPortPerday)
        if(this.voyageForm.portWork == ''|| typeof this.voyageForm.portWork =='undefined') fohe =fohe
        else fohe += parseFloat(this.voyageForm.portWork)
        //柴油价格
        if(this.voyageForm.doPrice == ''|| typeof this.voyageForm.doPrice =='undefined') doprice = 0
        else doprice = parseFloat(this.voyageForm.doPrice)
        //柴油总量
        if(this.voyageForm.doFuelLightSeaPerday == ''|| typeof this.voyageForm.doFuelLightSeaPerday =='undefined') dohe =0
        else dohe +=  parseFloat(this.voyageForm.doFuelLightSeaPerday)
        if(this.voyageForm.doFuelHeavySeaPerday == ''|| typeof this.voyageForm.doFuelHeavySeaPerday =='undefined') dohe =dohe
        else dohe +=  parseFloat(this.voyageForm.doFuelHeavySeaPerday)
        if(this.voyageForm.doFuelPortPerday == ''|| typeof this.voyageForm.doFuelPortPerday =='undefined') dohe =dohe
        else dohe +=  parseFloat(this.voyageForm.doFuelPortPerday)
        if(this.voyageForm.doWork == ''|| typeof this.voyageForm.doWork =='undefined') dohe =dohe
        else dohe +=  parseFloat(this.voyageForm.doWork)
        total = foprice*fohe + doprice*dohe
        return parseFloat(total).toFixed(2);
      },
      //回扣佣金
      discountComm:function() {
        let total = 0;
        for (let i=0;i<this.cargoForm.length;i++){
          if(this.cargoForm[i].discountComm == ''|| typeof this.cargoForm[i].discountComm=='undefined') total +=0
          else
          total += parseFloat(this.cargoForm[i].discountComm)
        }
        return parseFloat(total).toFixed(2);
      },
      //经纪人佣金
      brokerComm:function() {
        let total = 0;
        for (let i=0;i<this.cargoForm.length;i++){
          if(this.cargoForm[i].brokerComm == ''|| typeof this.cargoForm[i].brokerComm=='undefined') total +=0
          else
          total +=parseFloat( this.cargoForm[i].brokerComm)
        }
        return parseFloat(total).toFixed(2);
      },
      //总船舶租金(净)=日租金*(1-租金佣金)*总在航在港时间
      totalShipRent:function() {
        let total = 0
        let rent = 0
        for (let i=0;i<this.cargoForm.length;i++){
          if(this.cargoForm[i].discountComm == ''|| typeof this.cargoForm[i].discountComm=='undefined') rent +=0
          else
            rent += parseFloat(this.cargoForm[i].discountComm)
          if(this.cargoForm[i].brokerComm == ''|| typeof this.cargoForm[i].brokerComm=='undefined') rent +=0
          else
            rent +=parseFloat( this.cargoForm[i].brokerComm)
        }
        if(this.voyageForm.shipRentPerday=='' || typeof this.voyageForm.shipRentPerday == 'undefined') total= 0
        else total = this.allvoyagetime*parseFloat(this.voyageForm.shipRentPerday)  //日租金*总在航在港时间
        if(rent=='' || typeof rent == 'undefined') total= total*1
        else   total = total*(1-parseFloat(rent)/100)  //1-租金佣金
        return parseFloat(total).toFixed(2)
      },
      //租金佣金=货物的回扣佣金+经纪人佣金
      totalRentCost:function() {
        let total =0
        // if(this.voyageForm.commission == ''|| typeof this.voyageForm.commission=='undefined') total =0
        // else
        // total = parseFloat(this.voyageForm.commission)
        for (let i=0;i<this.cargoForm.length;i++){
          if(this.cargoForm[i].discountComm == ''|| typeof this.cargoForm[i].discountComm=='undefined') total +=0
          else
            total += parseFloat(this.cargoForm[i].discountComm)
          if(this.cargoForm[i].brokerComm == ''|| typeof this.cargoForm[i].brokerComm=='undefined') total +=0
          else
            total +=parseFloat( this.cargoForm[i].brokerComm)
        }
        return parseFloat(total).toFixed(2);
      },
      //其他费用
      totalOtherCost:function() {
        let cargoother = 0,other = 0,total =0;
        for (let i=0;i<this.cargoForm.length;i++){
          if(this.cargoForm[i].others == ''|| typeof this.cargoForm[i].others=='undefined') cargoother +=0
          else cargoother += parseFloat(this.cargoForm[i].others)
        }
        if(this.voyageForm.demurrageCharges == ''|| typeof this.voyageForm.demurrageCharges=='undefined')  other = 0
        else  other = parseFloat(this.voyageForm.demurrageCharges)
        if(this.voyageForm.dispatchMoney == ''|| typeof this.voyageForm.dispatchMoney=='undefined')  other = other
        else  other += parseFloat(this.voyageForm.dispatchMoney)
        if(this.voyageForm.otherMoney == ''|| typeof this.voyageForm.otherMoney=='undefined')  other = other
        else  other += parseFloat(this.voyageForm.otherMoney)
        if(this.voyageForm.scanStorage == ''|| typeof this.voyageForm.scanStorage=='undefined')  other = other
        else  other += parseFloat(this.voyageForm.scanStorage)

        total = other+cargoother
        return parseFloat(total).toFixed(2);
      },
      //总成本=总燃油费+港口费用+总船舶租金(净)+吨税+其他费用
      totalCost:function() {
        let total = 0;
        //，totalFuelFees：燃油费 totalPortFees：港口使费  totalTonnageDues吨税  totalOtherCost其他费用  totalShipRent净租金  dailyEqualRent：相当租金水平
       total = parseFloat(this.totalTonnageDues)+parseFloat(this.totalFuelFees)+parseFloat(this.totalPortFees)+parseFloat(this.totalOtherCost)+parseFloat(this.totalShipRent)
        return  parseFloat(total).toFixed(2)
      },
      //相当租金水平=（总运费收益（货物运费收益）-总成本-（总船舶租金（净）））/总航行天数
      dailyEqualRent:function() {
        let total = 0;
        let result = 0;
        if (this.freightrevenue==''||typeof this.freightrevenue =='undefined'){total =0}//货物运费收益
        else   total = parseFloat(this.freightrevenue)//货量
        if (this.totalCost==''||typeof this.totalCost =='undefined'){total-=0}  //总成本
        else   total -= parseFloat(this.totalCost)
        if (this.totalShipRent==''||typeof this.totalShipRent =='undefined'){total-=0} //总船舶租金
        else   total -= parseFloat(this.totalShipRent)
        if (total == ''||typeof total =='undefined'){total-=0;}
        else   total =parseFloat(this.freightrevenue)-parseFloat(this.totalCost)-parseFloat(this.totalShipRent)
         //total= parseFloat(this.freightrevenue)-parseFloat(this.totalCost)-parseFloat(this.totalShipRent) //total=总运费收益（货物运费收益）-总成本-（总船舶租金（净））
        if (this.totalSailingDays ==''||typeof this.totalSailingDays =='undefined'){   //总航行天数
          result = total/1
        } else{
          result = total/parseFloat(this.totalSailingDays) //result=total/总航行天数
        }
        if (result==''||typeof result=='undefined'){result=0}
        return  parseFloat(result).toFixed(2)
      },
      // 货物运费收益=货量*运费*（1-吨税费） *（1–经纪人佣金%-回扣佣金%）
      freightrevenue:function() {
        let total = 1,result = 0;
        let comm = 0;
        let tax =0;
        for (let i=0;i<this.cargoForm.length;i++){
          if(this.cargoForm[i].quantity=='' || typeof this.cargoForm[i].quantity == 'undefined') total = 0
          else   total *= parseFloat(this.cargoForm[i].quantity)//货量
          if(this.cargoForm[i].freight=='' || typeof this.cargoForm[i].freight == 'undefined') total = 0
           else  total *= parseFloat(this.cargoForm[i].freight)//运费*货量
          if(this.cargoForm[i].brokerComm=='' || typeof this.cargoForm[i].brokerComm == 'undefined') comm = 0
          else comm +=  parseFloat(this.cargoForm[i].brokerComm)/100 //经济人佣金%
          if(this.cargoForm[i].discountComm=='' || typeof this.cargoForm[i].discountComm == 'undefined') comm += comm
          else  comm +=  parseFloat(this.cargoForm[i].discountComm)/100//回扣佣金%+经济人佣金%
          if(this.cargoForm[i].freightTax=='' || typeof this.cargoForm[i].freightTax == 'undefined') tax+=0
          else tax  +=  parseFloat(this.cargoForm[i].freightTax)/100 // 运费税%
          result += total * (1 - comm)*(1-tax)
        }
        return parseFloat(result).toFixed(2)
      },
      //盈亏=总运费收入-总成本
      loss:function() {
        let result = this.freightrevenue - this.totalCost
        return parseFloat(result).toFixed(2);
      },
      //单位净收益
      profitper:function() {
        let quantity = 0
        let cargo = 0
        for (let i=0;i<this.cargoForm.length;i++){
          if(this.cargoForm[i].quantity=='' || typeof this.cargoForm[i].quantity == 'undefined') quantity +=0
          else quantity += parseFloat(this.cargoForm[i].quantity)
        }
        if(this.freightrevenue == 0 || quantity == 0) return 0
        else cargo = this.freightrevenue/quantity
        return parseFloat(cargo).toFixed(2);
      },
      //每日净收益
      perfitperday:function() {
        let total = 0
        if(this.freightrevenue == 0 || this.allvoyagetime == 0) return 0
        else total = this.freightrevenue/this.allvoyagetime
        return  parseFloat(total).toFixed(2);
      }
    },
    mounted(){
      this.isEorD = this.$route.params.eord
     this.lang = localStorage.getItem('lang')
      if (this.isEorD ==1 || this.isEorD == 2) {
        this.init_voyage()
      }
      this.buttonpermission = this.permission == null? localStorage.getItem('permission'):this.permission;
        if(this.buttonpermission.includes('72001')){
          this.disabled = false;
        }
        else this.disabled = true;

    },
    methods: {
      //修改航次估算信息-加载信息
      init_voyage() {
        this.voyageid = this.$route.params.id
        this.isEorD = this.$route.params.eord
        if(this.isEorD == 2 || this.isEorD == 1){
          selectVoyagebyId(this.voyageid).then(response =>{
            this.voyageForm= response.data.voyageModel
             this.shipForm = response.data.voyagePortModelList
             this.cargoForm = response.data.voyageCargoModelList
             this.resultForm = response.data.voyageTotalModel
            this.voyageForm.vesselName = this.lang == 'en'? response.data.voyageModel.vesselEname :response.data.voyageModel.vesselCname
            for (let i=0;i<this.shipForm.length;i++){
              this.shipForm[i].portName = this.lang == 'en'? response.data.voyagePortModelList[i].portEname :response.data.voyagePortModelList[i].portCname
            }
            for (let i=0;i<this.cargoForm.length;i++){
              this.cargoForm[i].cargoName = this.lang == 'en'? response.data.voyageCargoModelList[i].cargoEname :response.data.voyageCargoModelList[i].cargoCname
              this.cargoForm[i].loadingPortName = this.lang == 'en'? response.data.voyageCargoModelList[i].loadingPortEname :response.data.voyageCargoModelList[i].loadingPortCname
              this.cargoForm[i].dischargePortName = this.lang == 'en'? response.data.voyageCargoModelList[i].dischargePortEname :response.data.voyageCargoModelList[i].dischargePortCname
            }
          })
        }
      },
        downPdf(){
          let collapse = document.getElementsByTagName('el-collapse')
          for (let item of collapse) {
            item.prototype.accordion = false;
          }
         this.activeNames = ['1','2','3','4','5','6'];
        var inputs = document.querySelectorAll('input');
        for (var k = 0; k < inputs.length; k++) {
        if (inputs[k].type == "text") {
          inputs[k].setAttribute('value', inputs[k].value)
        } else {
          inputs[k].setAttribute('value', inputs[k].value)
        }
       }
          setTimeout(()=>{
            let leftdom1 = document.getElementById("demo1")
            let leftdom2 = document.getElementById("demo2")
            let leftdom3 = document.getElementById("demo3")
            let leftdom4 = document.getElementById("demo4")
            let leftdom5 = document.getElementById("demo5")
            let leftdom6 = document.getElementById("demo6")
            let rightdom1 = document.getElementById("right1")
            let rightdom2= document.getElementById("right2")
            let rightdom3= document.getElementById("right3")
            let out = document.createElement("div")
            let out1 = document.createElement("div")
            let out2 = document.createElement("div")
            out1.append(leftdom1.cloneNode(true))
            out1.append(leftdom2.cloneNode(true))
            out1.append(leftdom3.cloneNode(true))
            out1.append(leftdom4.cloneNode(true))
            out1.append(leftdom5.cloneNode(true))
            out1.append(leftdom6.cloneNode(true))
            out2.append(rightdom1.cloneNode(true))
            out2.append(rightdom2.cloneNode(true))
            out2.append(rightdom3.cloneNode(true))
            out.append(out2)
            out.append(out1)
            this.$print(out)
          },1000)
        },
        //在当前位置下方添加卡片
        addport(n) {
          this.lst.splice(n+1, 0, {})
          this.shipForm.splice(n+1, 0, {})
          for (let i=0;i<this.shipForm.length;i++){
            if(i == n+1){
              if(typeof this.shipForm[i].voyageDistance == 'undefined' || this.shipForm[i].voyageDistance == '') this.voyageDistance+=0
            }
          }
        },
      addcargo(n){
        this.cargolst.splice(n+1, 0, {})
        this.cargoForm.splice(n+1, 0, {})
      },
      delcargo(n){
        if(this.cargoForm.length > 1){
          this.cargolst.splice(n, 1)
          this.cargoForm.splice(n, 1)
        }
        else{
          this.$message.warning('至少填写一条货盘信息')
        }
      },
        //卡片上移或者下移
        cardmove(index, type){
            this.shipForm.splice(type ? index : index - 1, 1, ...this.shipForm.splice(type ? index + 1 : index, 1, this.shipForm[type ? index : index - 1]));
        },
        //删除指定卡片
        del(n) {
          if (this.shipForm.length>1){
            this.lst.splice(n, 1)
            this.shipForm.splice(n, 1)
          }
          else{
            this.$message.warning('至少填写一条港口信息')
          }
        },
        log(event) {
          },
        handleChange() {
          if (this.active++ > 3) {
            this.active = 0;
            this.activeNamesNum = 0;
          } else {
            this.activeNamesNum++;
          }
          var obj = document.getElementById('activeNames');
          // obj.label.style.backgroundColor= "red";

         },
      querySearchVessel(queryString, callback){
        this.$store.commit('metadata/querySearchVessel',queryString)
        callback(this.vesselnamegroup)
      },
      selectvessel(item){
        if(this.lang == 'en'){
          this.voyageForm.vesselId = item.id
          this.voyageForm.vesselCname = item.cname
          this.voyageForm.vesselEname = item.value

        }else {
          this.voyageForm.vesselId = item.id
          this.voyageForm.vesselCname = item.value
          this.voyageForm.vesselEname = item.ename
        }
        getshipinfo(this.voyageForm.vesselId).then(response =>{
          if(response.error_code == 200){
             this.voyageForm.dwt = response.data.dwt
            this.voyageForm.cbm = response.data.bale + response.data.grain
            this.voyageForm.knot = '13'
          }
        })
      },
      changevessel(){
        if(this.portnamegroup.length !== 0){
          for(let i = 0;i<this.portnamegroup.length;i++){
            if (this.portnamegroup[i].value == this.voyageForm.vesselName) {
              this.selectvessel(this.portnamegroup[i]);
              break
            }
          }
        }
      },
      addvoyagedistance(){
        this.voyageDistance +=0
      },
      getportValidate(index,val){
        this.portvalidate[index] = val
      },
      getcargoValidate(index,val){
        this.cargovalidate[index] = val
      },
      openedit(){
        this.deit_dialog=true
      },
      submits(){
        this.resultForm.totalVoyageDistance=this.totalVoyageDistance
        this.resultForm.totalSailingDays=this.totalSailingDays
        this.resultForm.totalSeaDays=this.totalSeaDays
        this.resultForm.allvoyagetime=this.allvoyagetime
        this.resultForm.firstPortEta=this.firstPortEta
        this.resultForm.lastPortEtd=this.lastPortEtd
        this.resultForm.totalFuelFees=this.totalFuelFees
        this.resultForm.totalPortFees=this.totalPortFees
        this.resultForm.totalDiscountComm=this.discountComm
        this.resultForm.brokerComm=this.brokerComm
        this.resultForm.totalShipRent=this.totalShipRent
        this.resultForm.totalRentCost=this.totalRentCost
        this.resultForm.totalTonnageDues=this.totalTonnageDues
        this.resultForm.totalOtherCost=this.totalOtherCost
        this.resultForm.totalCost=this.totalCost
        this.resultForm.totalFreightRevenue=this.freightrevenue
        this.resultForm.profitLoss=this.loss
        this.resultForm.profitPer=this.profitper
        this.resultForm.profitPerday=this.perfitperday

        for (let i=0;i<this.shipForm.length;i++){
            this.shipForm[i].sort = i
        }
        for (let i=0;i<this.cargoForm.length;i++){
            this.cargoForm[i].sort = i
        }
        const param = {
          voyageTotalModel:this.resultForm,
          voyagePortModelList:this.shipForm,
          voyageCargoModelList:this.cargoForm,
          voyageModel:this.voyageForm
          }
        //voyage表单校验
        this.$refs.voyageForm.validate((valid) => {
          if(valid){
          this.$refs.portitem.submit()
          if(this.portvalidate.indexOf(false)>-1){
            this.$message.warning(window.vm.$t('message.estimate.voyage.goonfill'))
            this.activeNames='2'
          }else{
            this.$refs.cargoitem.submit()
            if(this.cargovalidate.indexOf(false)>-1){
              this.$message.warning(window.vm.$t('message.estimate.voyage.goonfill'))
              this.activeNames='3'
            }else{
               addvoyage(param).then(response =>{
                if(response.error_code == 200){
                  this.$message.success(window.vm.$t('message.estimate.voyage.successadd'))
                  this.$router.push('/estimate/searchresult')
                }
              })
            }
          }}else{
          this.activeNames='1'
          this.$message.warning(window.vm.$t('message.estimate.voyage.goonfill'))
        }
        })
      },
    }

  }
</script>

<style lang="scss">
    .voyage{
        height: 100%;
        width: 100%;
        .elsteps{
            height: 100%;
            width:60%;
            float: left;
        }
        .el-form {
            height: 100%;
            width: 100%;
          .el-form-item__label{
            font-size:5px !important;
            padding: 0 5px 0 0;
          }
            .el-steps--vertical{
                    width:100%;
                    float: left;
            }
            .el-collapse{
                width:100%;
                .voyage_box{
                    .el-collapse-item__wrap{
                        width:100%;
                    }
                    .el-form-item{
                        margin-bottom: 8px;
                        .el-form-item__content .el-input-group {
                          vertical-align: baseline;
                        }
                    }
                    .el-input__inner{
                        height:25px;
                    }
                    .danwei{
                        line-height: 40px;
                    }

                }
                    .other_box{
                        width: 90% !important;
                        .el-collapse-item__wrap{
                            width:100%;
                        }
                        .el-form-item{
                            margin-bottom: 8px;
                        }
                        .el-input__inner{
                            height:25px;
                        }
                        .danwei{
                            line-height: 40px;
                        }

                    }
                    .shipfee_box{
                        .el-collapse-item__wrap{
                            width:100%;
                        }
                        .el-form-item{
                            margin-bottom: 8px;
                        }
                        .el-input__inner{
                            height:25px;
                        }
                        .danwei{
                            line-height: 40px;
                        }
                    }
                .fuel_box{
                    .el-collapse-item__wrap{
                        width:100%;
                    }
                    .el-form-item{
                        margin-bottom: 8px;
                    }
                    .el-input__inner{
                        height:25px;
                    }
                    .danwei{
                        line-height: 40px;
                    }
                }
                .ship_box{
                    .el-card__body{
                        padding: 5px;
                    }
                    .el-collapse-item__wrap{
                        width:110%;
                    }
                    .el-form-item{
                        margin-bottom: 8px;
                    }
                    .el-input__inner{
                        height:25px;
                    }
                    .danwei{
                        line-height: 40px;
                    }
                }
                .cargo_box{
                    .el-card__body{
                        padding: 5px;
                    }
                    .el-collapse-item__wrap{
                        width:110%;
                    }
                    .el-form-item{
                        margin-bottom: 8px;
                    }
                    .el-input__inner{
                        height:25px;
                    }
                    .danwei{
                        line-height: 40px;
                    }
                }
            }
        }
    }
    .out{
        margin-left: 60%;
        width:50%;
        height: 100%;
        .el-row {
            .el-row-inner{
              height: 35px;
            }
            .el-card__header{
                padding: 0;
            }
          .el-divider--horizontal {
            display: block;
            height: 1px;
            width: 100%;
            margin: 12px 0;
          }
            .el-card__body{
                padding-bottom: 0;
              padding: 5px;
            }
            .el-input__inner {
                height: 30px;
            }
            .danwei {
                //line-height: 40px;
              position: absolute;
              top: 10px;
            }
            .input_loss1{
                .el-input__inner{
                    color:red;
                }
            }
            .input_loss2{
                .el-input__inner{
                    color:green;
                }
            }
        }


    }


</style>
